<!-- @Author: Yu_Bo -->
<template>
	<div class='text_page'>
    <!--  -->
    <div class="page_top">
      <div class="page_top_con">
        <div @click="closeBtn">
          <i class="el-icon-back"></i>
          <span>返回</span>
        </div>
      </div>
    </div>
    <!--  -->
    <div class="page_con">
      <div v-html="htmlData"></div>
    </div>
	</div>
</template>

<script>
	export default {
		components: {},
		props: {},
		data() {
			return{
        title:'服务协议',
        time:'2022年8月1日',
        htmlData:'',
			}
		},
		computed: {

    },
		watch: {},
		created() {
      if(this.$route.params.type){
        // 服务协议
        if(this.$route.params.type==1){
          this.title='声音克隆协议'
        }
      }
      // this.infoData(this.$route.params.txt)
    },
		mounted() {},
		methods: {
      infoData(txt){
        var params = {
          name:txt
        }
        this.$publicApi.registerAgreement(params).then(res => {
          if (res.code == 1000) {
            this.htmlData=res.result
          } else {
            this.$errMsg(res.message)
          }
        })
      },
      // 关闭浏览器窗口
      closeBtn(){
        window.close()
      }
    },
	}
</script>

<style lang='scss' scoped>
	.text_page{
    width: 100%;
    min-height: 100vh;
    background: #fff;
    .page_top{
      position: sticky;
      top: 0;
      width: 100%;
      height: 60px;
      background: #fff;
      border-bottom: 1px solid #EDEDED;
      .page_top_con{
        width: 900px;
        height: 60px;
        margin: auto;
        display: flex;
        align-items: center;
        div{
          display: flex;
          align-items: center;
          .el-icon-back{
            cursor: pointer;
            font-size: 18px;
            margin-top: 2px;
          }
          span{
            cursor: pointer;
            padding-left: 6px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
          }
        }
      }
    }
    /*  */
    .page_con{
      width: 900px;
      margin: auto;
      padding: 40px 0;
      .con_title{
        text-align: center;
        font-size: 36px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
      }
      .con_time{
        padding: 40px 0;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }
      .con_html{
        width: 100%;
        .html_title{
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
        }
        .html_text{
          width: 100%;
          padding-bottom: 40px;
          p{
            padding-top: 10px;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
          }
        }
      }
    }
	}
</style>
